export const AUTH_POOL_ID = process.env.REACT_APP_USER_POOL_ID as string;
export const AUTH_CLIENT_ID = process.env
	.REACT_APP_USER_POOL_WEB_CLIENT_ID as string;
export const BASE_URL = process.env.REACT_APP_ESCROW_API_HOST as string;
export const LOCAL_USER_ID = process.env.REACT_APP_LOCAL_USER_ID as string;
export const ENV = process.env.REACT_APP_ENV as
	| 'local'
	| 'sandbox'
	| 'development'
	| 'production';

const environment = {
	AUTH_POOL_ID,
	AUTH_CLIENT_ID,
	BASE_URL,
	LOCAL_USER_ID,
	ENV,
};

export default environment;
