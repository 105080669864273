import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import EscrowPage from './Escrow';
import { EscrowStatement } from './pages/EscrowStatement';
import { EscrowDetailsPage } from './pages/EscrowDetails';
import { EscrowNaturalPersonDetailsPage } from './pages/EscrowDetails/NaturalPerson';
import { EscrowCreation } from './pages/EscrowCreation';
import EscrowProvider from './context/EscrowAccounts.context';
import EscrowEditionPage from './pages/EscrowEdition/EscrowEdition';
import { PaymentAccountCreationPage } from './pages/PaymentAccountCreation';
import EscrowAccountMenu from './pages/EscrowAccountMenu/EscrowAccountMenu';
import PixPage from './pages/Pix/Pix';

const EscrowModule = () => {
	return (
		<EscrowProvider>
			<Routes>
				<Route path=":id/pix" element={<PixPage />} />
				<Route path="new" element={<EscrowCreation />} />
				<Route path="new/:id" element={<EscrowCreation />} />

				<Route path=":id/details" element={<EscrowAccountMenu />} />
				<Route
					path="new-company"
					element={<PaymentAccountCreationPage />}
				/>
				<Route
					path="new-company/:id"
					element={<PaymentAccountCreationPage />}
				/>
				<Route path="edit/:id" element={<EscrowEditionPage />} />
				<Route path=":id/statement" element={<EscrowStatement />} />
				<Route
					path=":id/details/:tab"
					element={<EscrowDetailsPage />}
				/>
				<Route
					path=":id/natural-person/details/:tab"
					element={<EscrowNaturalPersonDetailsPage />}
				/>
				<Route path=":tab" element={<EscrowPage />} />
				<Route index element={<Navigate to="accounts" replace />} />
			</Routes>
		</EscrowProvider>
	);
};

export default EscrowModule;
