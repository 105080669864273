import { Button, notification } from 'antd';
import { BreadcrumbContainer } from 'components/Breadcrumb';
import { PageHeaderWithButton } from 'components/PageHeader/PageHeader';
import { TextM } from 'components/Text';
import { useBreadcrumb } from 'modules/core/context/AppContext';
import { EscrowRoutes } from 'modules/escrow/constants/routes';
import {
	getAccountRouteTypeIcon,
	getAccountRouteTypeName,
	useIsEscrowAccountRoute,
} from 'modules/escrow/utils/adminRoute';
import { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import theme from 'theme/theme';
import { AccountStatus } from 'types/Account';
import { EscrowBalance } from '../EscrowAccounts/styles';
import { useAccount } from 'modules/escrow/hooks/useAccount';
import { formatCurrency } from 'helpers/normalizers';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { useQuery } from '@tanstack/react-query';
import { AccountsService } from 'modules/escrow/services';
import { SplashScreen } from 'components/SplashScreen';
import PixEmptyState from './EmptyState/EmptyState';
import { StyledContainer, StyledTitle } from './Pix.styles';
import { LuPlusCircle } from 'react-icons/lu';

const PixPage = () => {
	const [api, contextHolder] = notification.useNotification();
	const { id: entityId } = useParams<{ id: string }>();
	const isEscrowRoute = useIsEscrowAccountRoute();
	const { setBreadcrumb } = useBreadcrumb();

	const [isOpenNewPixModal, setIsOpenNewPixModal] = useState(false);
	const [visibleBalances, setVisibleBalances] = useState<{
		[key: string]: number;
	}>({});

	const redirectAccount = isEscrowRoute
		? EscrowRoutes.ESCROW_BASE
		: EscrowRoutes.ACCOUNT_BASE;
	const title = (
		<BreadcrumbContainer>
			{getAccountRouteTypeIcon()}
			<span>{getAccountRouteTypeName()}</span>
		</BreadcrumbContainer>
	);

	const { data, isLoading } = useQuery({
		queryKey: ['accountDetails', entityId],
		queryFn: () => AccountsService.getAccount(entityId!),
		refetchOnWindowFocus: false,
		enabled: typeof entityId === 'string',
	});

	const { isBalanceLoading, getAccountBalance } = useAccount({
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Ocorreu um problema.',
			});
		},
		onBalanceSuccess: (account, amount) => {
			setVisibleBalances(prev => ({
				...prev,
				[account.id]: amount,
			}));
		},
	});

	if (isLoading) {
		return <SplashScreen />;
	}

	if (!data) {
		return (
			<Navigate
				to={
					isEscrowRoute
						? EscrowRoutes.ESCROW_BASE
						: EscrowRoutes.ACCOUNT_BASE
				}
			/>
		);
	}

	const isVisible = typeof visibleBalances[data.id] === 'number';
	const hasPermission = true;

	useEffect(() => {
		setBreadcrumb([
			{
				href: redirectAccount,
				title: title,
			},
			{
				href: `${redirectAccount}/${entityId}/details`,
				title: (
					<TextM
						color={theme.textGray}
					>{`Conta ${data?.person?.name || ''}`}</TextM>
				),
			},
			{
				title: 'Cobrança Pix',
			},
		]);
	}, []);

	return (
		<div>
			{contextHolder}
			<PageHeaderWithButton title="Cobrar com pix" isSticky hasDivider>
				{[AccountStatus.BLOCKED, AccountStatus.ACTIVE].includes(
					data.status,
				) && (
					<EscrowBalance isVisible={isVisible}>
						<TextM
							weight="bold"
							style={{
								marginRight: '0.6rem',
							}}
						>
							Saldo disponível:
						</TextM>
						<TextM weight="normal" color={theme.textGray}>
							{isVisible
								? formatCurrency(visibleBalances[data.id])
								: '*******'}
						</TextM>
						<Button
							type="link"
							size="large"
							icon={isVisible ? <BsEyeSlash /> : <BsEye />}
							loading={isBalanceLoading === entityId}
							onClick={() => {
								if (!isVisible) {
									getAccountBalance(data);
									return;
								}
								setVisibleBalances(prev => {
									delete prev[data.id];
									return { ...prev };
								});
							}}
						/>
					</EscrowBalance>
				)}
				{hasPermission && (
					<Button
						onClick={() => setIsOpenNewPixModal(true)}
						type="primary"
						style={{
							marginTop: '1rem',
							marginRight: '2rem',
							padding: '1.3rem',
							marginBottom: '1rem',
						}}
					>
						<TextM color={theme.white}>Criar nova cobrança</TextM>
						<LuPlusCircle
							style={{ marginLeft: '0.6rem' }}
							size={18}
						/>
					</Button>
				)}
			</PageHeaderWithButton>

			<StyledContainer>
				<StyledTitle>Minhas cobranças</StyledTitle>
				<PixEmptyState
					addNewPixHandler={() => setIsOpenNewPixModal(true)}
					hasPermission={hasPermission}
				/>
			</StyledContainer>
		</div>
	);
};

export default PixPage;
