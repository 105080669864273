import { Button, Empty } from 'antd';
import { TextM, TextS } from 'components/Text';
import emptyDocument from 'components/images/EmptyPix.png';
import { LuPlusCircle } from 'react-icons/lu';
import theme from 'theme/theme';

interface IPixEmptyStateProps {
	addNewPixHandler: () => void;
	hasPermission: boolean;
}

const PixEmptyState = ({
	addNewPixHandler,
	hasPermission,
}: IPixEmptyStateProps) => {
	return (
		<Empty
			style={{
				marginTop: '1.5rem',
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
			}}
			description={
				<div>
					<TextM
						weight="bold"
						color={theme.textSecondary}
						style={{ marginBottom: '1rem' }}
					>
						Nenhuma cobrança criada ainda
					</TextM>
					<TextS
						style={{
							color: theme.textGray,
							textAlign: 'center',
						}}
					>
						Aqui você poderá visualizar todas as suas cobranças
						futuras de forma rápida e segura.
					</TextS>
					<TextS
						style={{
							color: theme.textGray,
							textAlign: 'center',
						}}
					>
						Para criar sua primeira cobrança, clique no botão abaixo
						e preencha os detalhes necessários. É simples e prático!
					</TextS>
				</div>
			}
			image={emptyDocument}
			imageStyle={{
				height: 200,
			}}
		>
			{hasPermission && (
				<Button
					type="primary"
					onClick={addNewPixHandler}
					style={{ marginTop: '1rem' }}
				>
					Criar nova cobrança
					<LuPlusCircle style={{ marginLeft: '0.6rem' }} size={18} />
				</Button>
			)}
		</Empty>
	);
};

export default PixEmptyState;
